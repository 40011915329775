<template>
  <div class="re-easy-form">
    <div class="re-easy-form__content">
      <re-form :rules="rules" :form="filterForm" ref="easyForm" :labelConfig="labelConfig">
        <re-form-item :prop="item.prop" v-for="item of innerform" :key="item.prop" v-bind="item">
          <component :is="`re-${item.comp}`" v-model="item.value" v-bind="item" :ref="item.prop"/>
        </re-form-item>
      </re-form>
    </div>
    <slot />
  </div>
</template>

<script>
import ReForm from '@/components/form/ReForm.vue';
import ReFormItem from '@/components/form/ReFormItem.vue';
import ReInput from '@/components/form/ReInput.vue';
import ReSelect from '@/components/form/ReSelect.vue';
import ReSelectWithInputFilter from '@/components/form/ReSelectWithInputFilter.vue';
import ReDatePicker from '@/components/form/ReDatePicker.vue';
import ReEmailAutoComplete from '@/components/form/ReEmailAutoComplete.vue';
import ReAutoInput from '@/components/form/ReAutoInput.vue';

export default {
  name: 'ReEasyForm',
  components: {
    ReForm,
    ReFormItem,
    ReInput,
    ReDatePicker,
    ReSelect,
    ReSelectWithInputFilter,
    ReEmailAutoComplete,
    ReAutoInput
  },
  props: {
    rules: {
      type: Object,
      default: () => ({}),
    },

    labelConfig: {
      type: Object,
      default: () => ({
        position: 'left', // top, left
        width: '80',
        textAlign: 'right', // left, right
      }),
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      innerform: [],
    };
  },
  computed: {
    filterForm() {
      return this.value.reduce((obj, item) => {
        obj[item.prop] = item.value;

        return obj;
      }, {});
    },
  },
  methods: {
    setInnerValue() {
      this.innerform = this.value;
    },
    async validateForm(isValid = true) {
      if (isValid) {
        const isPass = await this.$refs.easyForm.validateForm(() => { });

        return isPass;
      }

      return false;
    },
    syncValue() {
      this.$emit('input', this.innerform);
    },
    removeErrorText() {
      this.$refs.easyForm.removeErrorText();
    }
  },

  created() {
    this.setInnerValue();
  },
  watch: {
    innerform: {
      handler: 'syncValue',
    },
    value: {
      handler: 'setInnerValue',
      deep: true
    }
  },
};
</script>

<style lang="scss" scoped>
.re-easy-form {
  &__content {
    margin-bottom: 30px;

  }
}
</style>
