<template>
  <div class="re-select" :style="{ 'max-width': `${maxWidth}` }" >

    <div class="re-select__input" @click="expandOptions" v-on-clickaway="closeOptions">
      <re-input
        v-model="selectedValue"
        :expand="expandConfig"
        readonly
        cus-type="select"
        :disabled="disabled"

        :placeholder="$attrs.placeholder"
      >

              <!-- @click.stop="expandOptions" -->
        <template #suffix v-if="$slots.suffix">
          <slot name="suffix"></slot>
        </template>
      </re-input>
      <div class="expand-down" :class="{ 'expand-down--active': expandConfig.status }">
        <img class="expand-down__icon" src="@/assets/icons/dropdown-arrow.svg" />
      </div>

    </div>
    <re-expand-container :visible.sync="expandConfig.status">
      <div class="re-select__option__content">
        <ul class="re-select__option__content__list" >
          <re-select-option
            v-for="opt of formatOptions"
            :key="opt.value"
            v-bind="opt"
            :currOption="value"
            @handleOption="handleOption"
          />
        </ul>
      </div>
    </re-expand-container>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import triggerValidate from '@/mixins/triggerValidate';
import ReSelectOption from '@/components/form/ReSelectOption.vue';
import ReExpandContainer from '@/components/form/ReExpandContainer.vue';
import ReInput from '@/components/form/ReInput.vue';

export default {
  name: 'ReSelect',
  mixins: [triggerValidate],
  components: {
    ReSelectOption,
    ReExpandContainer,
    ReInput
  },
  inject: {
    reFormItem: {
      default: ''
    }
  },
  directives: {
    onClickaway
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    maxWidth: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      expandConfig: {
        use: true,
        status: false
      },
    };
  },
  computed: {
    selectedValue() {
      const valueMappingObj = this.formatOptions.reduce((obj, ele) => {
        obj[ele.value] = ele.label;
        return obj;
      }, {});

      return valueMappingObj[this.value];
    },
    formatOptions() {
       const allOpts = this.options
        .reduce((optionsList, item) => {
          // 傳進來是群組時，把該 label 和 選項攤平，如何渲染是 SelectOption 的事
          if (item.options) {
            return [
              ...optionsList,
              {
                // type: 'group' 用來讓下面的 SelectOption 判斷是否為群組標籤
                type: 'label',
                label: item.label
              },
              ...item.options
            ];
          }

          return [...optionsList, item];
        }, []);

        // console.log('allOpts', allOpts);
        allOpts.splice(0, 0, {
          value: '-',
          label: this.$t('select'),
          disabled: true
        });

        return allOpts;
    },
    test() {
      return JSON.stringify(this.expandConfig);
    }
  },
  methods: {
    closeOptions() {
      this.expandConfig.status = false;
    },
    expandOptions() {
      this.$set(this.expandConfig, 'status', !this.expandConfig.status);
    },
    handleOption(value) {
      this.$emit('input', value);
      this.$emit('change', value);
      this.triggerValidate('change', value);
      this.closeOptions();
    },
  },
  created() {
    // console.log(this.options);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.re-select {
  position: relative;
  width: 100%;

  &__input {
    position: relative;
    width: 100%;
    cursor: pointer;
  }

  &__option {
    @include position(tl, 100%, 0);
    z-index: 100;
    margin-top: 5px;
    background-color: $c-white;
    border: 1px solid $c-main;
    border-radius: 4px;
    overflow: hidden;

    &__content {
      position: relative;
    }
  }
}

.expand-down {
  @include position(tr, 50%, 10px);
  transform: translateY(-50%) rotate(180deg);
  transition: 0.4s;

  &__icon {
    width: 15px;
  }

  &--active {
    transform: translateY(-50%) rotate(360deg);
  }
}
</style>
