/* eslint-disable */
import regExps from '@/utils/validate/regExp';
import { verifyTaiwanId } from '@/utils/validate/helper'
// 測試用 start
export const t1 = (value, errMsg, rule, args) => {
  if (value.length > 3) {
    return true;
  }

  return false;
};
export const t2 = (value, errMsg, rule, args) => {
  if (Number(value) > 3) {
    return true;
  }
  return false;
};
// 測試用 end

export const vldRequired = (value, errMsg, rule, args) => {
  if (value) {
    return true;
  }

  return false;
};

export const vldRequiredSelect = (value, errMsg, rule, args) => {
  if (value) {
    return true;
  }

  return false;
};

export const vldSelected = (value, errMsg, rule, args) => {
  if (value !== '-') {
    return true;
  }

  return false;
};

// export const vldLengthMoreThen = (value, errMsg, rule, args = 6) => {
//   if (value.length >= args) {
//     return true;
//   }

//   return false;
// };

export const vldLengthBetween = (value, errMsg, rule, args) => {
  const [lowBound, highBound] = args.split('~');

  if (value.length >= lowBound && value.length <= highBound) {
    return true;
  }

  return false;
};

export const vldLengthLessThen = (value, errMsg, rule, args = 6) => {
  if (value.length >= args) {
    return true;
  }

  return false;
};


export const vldEmail = (value, errMsg, rule, args) => {
  if (value) {
    return regExps.email.test(value);
  }

  return false;
};

// export const vldAllEmail = (value, errMsg, rule, args) => {
//   if (value) {
//     return value.every((item) => regExps.email.test(item.value));
//   }

//   return false;
// };

export const vldMobile = (value, errMsg, rule, args) => {
  if (value) {
    return regExps.mobile.test(value);
  }

  return false;
};

// 多驗證了國碼
export const vldSignUpMobile = (value, errMsg, rule, args, options) => {
  if (options.cb() === '+886') {
    return regExps.mobile.test(value);
  }

  // 只驗證是否為數字
  if (value) {
    return regExps.integer.test(value);
  }

  return false;
};

// 驗證帳號
export const vldAccount = (value, errMsg, rule, args, options) => {
  console.log('options.cb()', options.cb())
  if (options.cb() === 'TW') {
    return verifyTaiwanId(value);
  } else {
    return true;
  }
};

// 緊急聯絡人名字、關係、電話
export const vldEmergency = (value, errMsg, rule, args, options) => {
  // const madarinCount = value.match(/([\u4e00-\u9fa5])/g) || [];
  // const englishAndSpaceCount = value.match(/([a-zA-Z\s])/g) || [];
  // if((madarinCount.length * 2) + englishAndSpaceCount.length > 20 ) {
  //   return false;
  // }

  if(value.length > 20) {
    return false
  }

  return true;
};









// export const vldOnlyOneAvatar = (value, errMsg, rule, args) => {
//   if (value) {
//     return value.length === 1;
//   }

//   return false;
// };

export const vldCheckPassword = (value, errMsg, rule, args, options) => {
  if (value) {
    return !regExps.password.test(value);
  }

  return false;
};


export const vldCheckConfirmPassword = (value, errMsg, rule, args, options) => {
  if (value === options.cb()) {

    return true;

  }

  return false;
};

export const vldAutoInput = (value, errMsg, rule, args, options) => {
  if (value) {
    return value.length === Number(args);
  }

  return false;
};

export const vldCheckNumber = (value, errMsg, rule, args, options) => {
  if (value) {
    return regExps.integer.test(value);
  }

  return false;
};

export const vldEmergencyTel = (value, errMsg, rule, args, options) => {
  if (value) {
    const reg = new RegExp(regExps.emergencyTel, 'g');
    reg.lastIndex = 0;
    return reg.test(value);
  }

  return false;
};






