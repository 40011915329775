import i18n from '@/i18n';

export const t1 = (args) => `t1 default error, args:${args}`;
export const t2 = () => 't2 default error';

// export const vldRequired = () => '此為必填選項';
export const vldRequired = (_, options) => `${i18n.tc('pleaseEntry')}${options.label}`;
export const vldRequiredWithLabel = (_, options) => `${i18n.tc('pleaseEntry')}${options.label}`;

export const vldAutoInput = (arg) => `${i18n.tc('pleaseEntry')}${arg}${i18n.tc('digits')}`;

// export const vldLengthMoreThen = (args) => `長度需大於${args}${i18n.tc('digits')}`;
export const vldEmail = () => i18n.tc('validation.email');
export const vldMobile = () => i18n.tc('errMsg.vldMobile');
export const vldSignUpMobile = () => i18n.tc('errMsg.vldMobile');
export const vldAccount = () => i18n.tc('errMsg.vldAccount');
export const vldCheckPassword = () => i18n.tc('errMsg.vldCheckPassword');

export const vldLengthBetween = (args) => `${i18n.tc('lengthBetween')}${args}${i18n.tc('digits')}`;
export const vldCheckConfirmPassword = () => i18n.tc('errMsg.vldCheckConfirmPassword');
export const vldSelected = (_, options) => `${i18n.tc('select')}${options.label}`;
export const vldCheckNumber = () => `${i18n.tc('pleaseInputNumber')}`;
export const vldRequiredSelect = (_, options) => `${i18n.tc('select')}${options.label}`;
export const vldEmergency = () => i18n.tc('emergencyErrorHint');
// export const vldEmergencyTel = () => i18n.tc('emergencyErrorHint');
// 不想新增新的多語言提示，所借 RN 的來魔改 ...
export const vldEmergencyTel = () => i18n.tc('customSWALInputCorrectNumber').replace('}', 20);

// export const vldAllEmail = () => '所有輸入框都必須符合 Email 格式';
// export const vldOnlyOneAvatar = () => '請上傳一張照片';
