import { vld } from '@/utils/validate/vld.js';
import i18n from '@/i18n';

export const loginRule = {
  account: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired'],
        options,
        label: i18n.tc('accout'),
      }),
    trigger: ['input'],
  },
  password: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        // ruleList: ['vldRequired', 'vldLengthBetween:8~20'],
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20'],
        options,
        label: i18n.tc('password'),
      }),
    trigger: ['input'],
  }
};

export const signUpRule = (formValue) => ({
  name: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired'],
        options,
        label: i18n.tc('name'),
      }),
    trigger: ['input'],
  },
  country: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequiredSelect'],
        options,
        label: i18n.tc('register.nation'),
      }),
    trigger: ['change'],
  },
  account: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldAccount'],
        options,
        label: i18n.tc('uidOrPassportNum'),
        cb: () => formValue.country,
      }),
    trigger: ['input'],
  },
  timezone: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldSelected'],
        options,
        label: i18n.tc('register.timeZone'),
      }),
    trigger: ['change'],
  },
  countryCode: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldSelected'],
        options,
        label: i18n.tc('countryCode'),
      }),
    trigger: ['change'],
  },
  phone: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldSignUpMobile'],
        options,
        label: i18n.tc('phoneNumber'),
        cb: () => formValue.countryCode,
      }),
    trigger: ['input'],
  },
  sex: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldSelected'],
        options,
        label: i18n.tc('gender'),
      }),
    trigger: ['change'],
  },
  addr: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired'],
        options,
        label: i18n.tc('address'),
      }),
    trigger: ['input'],
  },
  birthday: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired'],
        options,
        label: i18n.tc('date'),
      }),
    trigger: ['change'],
  },
  emergencyName: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldEmergency'],
        options,
        label: i18n.tc('emergencyName'),
      }),
    trigger: ['input'],
  },
  emergencyRelative: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldEmergency'],
        options,
        label: i18n.tc('relative'),
      }),
    trigger: ['input'],
  },
  emergencyTel: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldEmergencyTel'],
        options,
        label: i18n.tc('emergencyTel'),
      }),
    trigger: ['input'],
  },
  email: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldEmail'],
        options,
        label: 'Email',
      }),
    trigger: ['input', 'change'],
  },
  password: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20'],
        options,
        label: i18n.tc('password'),

      }),
    trigger: ['input'],
  },
  confirmPassword: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20', 'vldCheckConfirmPassword'],
        options,
        label: i18n.tc('confirmPassword'),
        cb: () => formValue.password,
      }),
    trigger: ['input'],
  }

});

export const resetPasswordRule = (formValue) => ({
  password: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20'],
        options,
        label: i18n.tc('password'),

      }),
    trigger: ['input'],
  },
  confirmPassword: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20', 'vldCheckConfirmPassword'],
        options,
        label: i18n.tc('confirmPassword'),
        cb: () => formValue.password,
      }),
    trigger: ['input'],
  }
});

export const forgetPasswordRule = {
  account: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired'],
        options,
        label: i18n.tc('accout'),
      }),
    trigger: ['input'],
  },
  email: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired'],
        options,
        label: 'Email',
      }),
    trigger: ['change'],
  }
};

export const modifyPasswordRule = (formValue) => ({
  // originPassword: {
  //   validator: (rule, value, cb, src, options) =>
  //     vld({
  //       value,
  //       ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20'],
  //       options,
  //       label: '原密碼',

  //     }),
  //   trigger: ['input'],
  // },
  newPassword: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20'],
        options,
        label: i18n.tc('newPassword'),

      }),
    trigger: ['input'],
  },
  confirmNewPassword: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldCheckPassword', 'vldLengthBetween:8~20', 'vldCheckConfirmPassword'],
        options,
        label: i18n.tc('comfirmNewPassword'),
        cb: () => formValue.newPassword,
      }),
    trigger: ['input'],
  }
});

export const validateRule = {
  code: {
    validator: (rule, value, cb, src, options) =>
      vld({
        value,
        ruleList: ['vldRequired', 'vldAutoInput:4'],
        options,
        label: i18n.tc('verification'),
      }),
    trigger: ['change'],
  }
};
