var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-form-item",class:[
  `re-form-item--${_vm.labelConfig().position}`,
  `re-form-item--layout-width--${_vm.$attrs.layoutWidth}`,
  {
    're-form-item--top-padding': _vm.addLabelPadding
  },
  { 'mtauto' : `${_vm.$attrs.mtauto}`}
],attrs:{"id":_vm.prop}},[_c('label',{staticClass:"re-form-item__label",class:[
    _vm.labelConfig().position === 'left' && `re-form-item__label--${_vm.labelConfig().textAlign}`
  ],style:(`width: ${_vm.labelWidthValue}`)},[(_vm.$attrs.required)?_c('span',{staticClass:"re-form-item__label__required"},[_vm._v("*")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$attrs.label))])]),_c('div',{staticClass:"re-form-item__box"},[_c('div',{ref:"formItemContent",staticClass:"re-form-item__box__content"},[(_vm.$attrs.notion1)?_c('span',{staticClass:"notion"},[_vm._v(_vm._s(_vm.$attrs.notion1))]):_vm._e(),(_vm.$attrs.notion2)?_c('span',{staticClass:"notion"},[_vm._v(_vm._s(_vm.$attrs.notion2))]):_vm._e(),_vm._t("default")],2),_c('p',{staticClass:"re-form-item__box__err-msg"},[_vm._v(" "+_vm._s(_vm.errMsgText)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }