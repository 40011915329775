<template>
  <div class="c-deco-title">
    <p class="eng-title">{{ engTitle }}</p>
    <p class="title">
      <span class="title__main">{{ title }}</span>
      <img class="title__icon" src="@/assets/img/deco_flower.svg" alt="" />
    </p>
  </div>
</template>
<script>
export default {
  name: 'DecoTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    engTitle: {
      type: String,
      default: '',
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/other/color.scss";
@import "@/assets/scss/other/mixin.scss";

.c-deco-title {
  @include flex(center, center, column);
  margin-bottom: 30px;
}

.eng-title {
  @include font-style($c-black, 40, 700, 3px);
  text-align: center;
}

.title {
  @include flex(center, center);

  &__main {
    text-align: center;
    @include font-style($c-black, 20, 400, 3px);
  }

  &__icon {
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .eng-title {
    @include font-size(24);
  }

  .title {
    &__main {
      @include font-style($c-black, 16, 400, 3px);
    }
  }
}
</style>
