<template>
  <div class="re-form-item" :class="[
    `re-form-item--${labelConfig().position}`,
    `re-form-item--layout-width--${$attrs.layoutWidth}`,
    {
      're-form-item--top-padding': addLabelPadding
    },
    { 'mtauto' : `${$attrs.mtauto}`}
  ]" :id="prop">
    <label class="re-form-item__label" :class="[
      labelConfig().position === 'left' && `re-form-item__label--${labelConfig().textAlign}`
    ]" :style="`width: ${labelWidthValue}`">
      <span v-if="$attrs.required" class="re-form-item__label__required">*</span><span>{{ $attrs.label }}</span>
    </label>
    <div class="re-form-item__box">
      <div class="re-form-item__box__content" ref="formItemContent">
        <span v-if="$attrs.notion1" class="notion">{{ $attrs.notion1 }}</span>
        <span v-if="$attrs.notion2" class="notion">{{ $attrs.notion2 }}</span>
        <slot></slot>
      </div>
      <p class="re-form-item__box__err-msg">
        {{ errMsgText }}
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Schema from "async-validator";
import { vld, asyncVld } from "@/utils/validate/vld";
// 移除console中的warning
Schema.warning = () => { };

export default {
  name: "ReFormItem",
  compName: "ReFormItem",
  inject: ["reForm", "labelConfig", "formValue"],
  props: {
    prop: {
      type: String,
      default: ""
    },
    addPadding: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPassValidate: true,
      addLabelPaddingList: ["ReTextarea", "ReInputList", "ReUpload"],
      currFormItemChildrenName: [],
      errMsgText: "",
      validateStatus: true
    };
  },
  provide() {
    return {
      reFormItem: this
    };
  },
  computed: {
    addLabelPadding() {
      if (this.addPadding) {
        return true;
      }

      for (const childName of this.currFormItemChildrenName) {
        for (const item of this.addLabelPaddingList) {
          if (childName === item) return true;
        }
      }

      return false;
    },
    labelWidthValue() {
      if (!this.labelConfig().width) {
        return "100px";
      }

      return `${this.labelConfig().width}px`;
    },
    itemTrigger() {
      if (this.prop in this.reForm.rules) {
        if ("trigger" in this.reForm.rules[this.prop]) {
          return ["form", ...this.reForm.rules[this.prop].trigger];
        }
      }

      return ["form", "change"];
    },
    itemRule() {
      return { [this.prop]: this.reForm.rules[this.prop] };
    }
  },
  methods: {
    validateValue(val) {
      if (this.reForm.rules[this.prop]) {
        this.isPassValidate = this.reForm.rules[this.prop](val);
      }
    },
    validatePass() {
      this.errMsgText = "";
    },
    handleErrors(errorList) {
      const err = errorList.find(item => item.field === this.prop);
      this.errMsgText = err.message;
    },
    validateFormValue(value, event, options) {
      // 沒有規則就不驗證
      if (!this.itemRule[this.prop]) {
        return Promise.resolve(true);
      }

      // 至少都有一個form 當作是判斷submit時的驗證
      // 包含extra 是一個例外，表示必須往下跑驗證
      if (!this.itemTrigger.includes(event) && event.indexOf("extra") === -1) {
        return Promise.resolve(true);
      }

      let targetValue = value;
      // 若由父層form組件call此function，value會是obj要解構出來

      const propExist = Object.prototype.hasOwnProperty.call(value, this.prop);

      if (typeof value === "object" && propExist) {
        targetValue = value[this.prop];
      }

      const validator = new Schema(this.itemRule);
      const valueObj = { [this.prop]: targetValue };

      return new Promise(resolve => {


        try {
          validator
            .validate(valueObj, options, (errors, fields) => {
              if (errors) {
                this.handleErrors(errors, fields);
              } else {
                this.validatePass();
              }
            })
            .then(() => {
              // 通過直接回true
              resolve({
                status: true,
                key: this.prop
              });
            })
            .catch(() => {
              resolve({
                status: false,
                key: this.prop
              });
            });
        } catch (e) {
          resolve({
            status: true,
            key: this.prop
          });
        }
      });
    },
    getChildrenName() {
      this.$children.forEach(ele => {
        this.currFormItemChildrenName.push(ele.$options.name);
      });
    }
  },

  mounted() {
    this.getChildrenName();
  }
};
</script>

<style lang="scss" scoped>
$form-item: ".re-form-item";
@import "@/assets/scss/other/color.scss";

.re-form-item {
  display: flex;
  margin-bottom: 25px;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }

  &--top {
    flex-direction: column;

    #{$form-item}__label {
      width: 100%;
      margin-bottom: 5px;
      height: 20px;

    }

    &__box {
      width: 100%;

      &__content {}
    }
  }

  &--left {
    flex-direction: row;
    align-items: center;

    #{$form-item}__label {
      display: inline-block;
      flex: none;
      width: 80px;
      margin-right: 10px;
    }

    &__box {
      flex: 1;

      &__content {
        width: 100%;
      }
    }
  }

  // 一般情況下，form item高度最高為40px(最大的input),
  // 但content中的高度若高於40，label置中會有點微妙，所以這裡移除置中並設一點margin
  &--top-padding {
    align-items: flex-start;

    >#{$form-item}__label {
      margin-top: 11px;
    }
  }

  &--hide {
    .re-form-item__label {
      display: none;
    }
  }

  &--layout-width {

    &--20 {
      width: 20%;

      @media screen and (max-width: 768px) {
        width: 150px;
      }
    }

    &--30 {
      width: 30%;

      @media screen and (max-width: 768px) {
        width: calc(100% - 150px);
      }
    }


    &--50 {
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &--100 {
      width: 100%;
    }
  }

  &__label {
    font-size: 14px;
    color: $c-black;

    &__required {
      color: $color-theme
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }

  &__box {
    // margin-bottom: 20px;
    position: relative;
    width: 100%;

    &__content {
      width: 100%;
      // @include padding(4px 0);
    }

    &__err-msg {
      position: absolute;
      height: 12px;
      width: 100%;
      color: #cd5c5c;
      font-size: 12px;
    }
  }
}
.notion {
  display: block;
  font-size: 12px;
  margin-left: 8px;
  margin-bottom: 4px;
}
.mtauto {
  margin-top: auto;
}
</style>
