<template>
  <li
    class="re-select-option"
    :class="[
      {
        're-select-option--active': String(currOption) === String(value),
        're-select-option--disabled': disabled,
      },
    ]"
    :data-option-value="value"
    :data-disabled-status="disabled"
    :selected="String(currOption) === String(value)"
    @click="handleSelect(value)"
  >
    <component v-if="render" :is="render()" v-bind="optionConfig" />
    <span v-else class="re-select-option__item">{{ label }}</span>
    <span
      v-show="allowedDelete"
      class="re-select-option__remove"
      :allowedDelete="allowedDelete"
      @click.stop="removeOption(value)"
      >刪除</span
    >
  </li>
</template>

<script>
export default {
  name: 'ReSelectOption',
  props: {
    value: {
      default: '',
    },
    optionConfig: {
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    render: {
      type: Function,
    },
    currOption: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowedDelete: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelect(val) {
      this.$emit('handleSelect', val);
    },
    removeOption(val) {
      this.$emit('removeOption', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/other/mixin.scss';
@import '@/assets/scss/other/color.scss';

.re-select-option {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  @include font-style($c-black, 14px);
  @include padding(10px);
  position: relative;

  &:not([data-disabled-status='true']) {
    &:hover {
      background-color: rgba($c-main, 0.2);
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--active {
    background-color: rgba($c-main, 0.2);
  }

  &__remove {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    @include font-style($c-error, 14px);
    cursor: pointer;
  }
}
</style>
