/* eslint-disable */

import * as allRules from '@/utils/validate/rules';
import * as errorMessage from '@/utils/validate/errorMessage';

function verifyTaiwanId(id) {
  id = id.trim();

  if (id.length != 10) {
      // console.log("Fail，長度不正確");
      return false
  }


  let countyCode = id.charCodeAt(0);
  if (countyCode < 65 | countyCode > 90) {
      // console.log("Fail，字首英文代號，縣市不正確");
      return false
  }

  let genderCode = id.charCodeAt(1);
  if (genderCode != 49 && genderCode != 50) {
      // console.log("Fail，性別代碼不正確");
      return false
  }

  let serialCode = id.slice(2)
  for (let i in serialCode) {
      let c = serialCode.charCodeAt(i);
      if (c < 48 | c > 57) {
          // console.log("Fail，數字區出現非數字字元");
          return false
      }
  }

  let conver = "ABCDEFGHJKLMNPQRSTUVXYWZIO"
  let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

  id = String(conver.indexOf(id[0]) + 10) + id.slice(1);

  let checkSum = 0
  for (let i = 0; i < id.length; i++) {
      const c = parseInt(id[i])
      const w = weights[i]
      checkSum += c * w
  }

  const verification = checkSum % 10 == 0

  return verification
}



const validator = {
  ruleList: [],
  vldFunc(value, ruleError, rule, args, options) {
    const validate = {
      ...allRules,
    };

    const isPass = validate[rule](value, ruleError, rule, args, options);

    if (!isPass) {
      return this.errMsg(ruleError, rule, args, options);
    }
  },

  // 取得錯誤訊息
  getErrorMessage(rule, args, options) {
    const defaultErrMsg = {
      ...errorMessage,
    };

    return defaultErrMsg[rule](args, options);
  },

  // 回傳錯誤訊息
  errMsg(ruleError, rule, args, options) {
    let msg = '';
    if (rule in ruleError) {
      msg = this.getErrorMessage(ruleError[rule], args, options);
    } else {
      msg = this.getErrorMessage(rule, args, options);
    }

    return {
      rule,
      msg,
      pass: false,
    };
  },

  // 將驗證規則加入陣列
  add(value, rules, ruleError, options) {

    rules.forEach((r) => {
      const rule = r.split(':')[0]; // 規則名稱
      const args = r.split(':')[1] || null; // 額外參數

      this.ruleList.push(() => this.vldFunc.call(this, value, ruleError, rule, args, options));
    });
  },

  // 開始驗證
  
  start() {
    for (const fn of this.ruleList) {
      

      const msg = fn();

      if (msg) {
        this.cleanRuleList();
        return msg;
      }
    }

    this.cleanRuleList();
    return {
      pass: true,
    };
  },

  cleanRuleList() {
    this.ruleList = [];
  },
};

export default validator;
export {verifyTaiwanId}