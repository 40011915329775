<template>
  <div class="re-date-picker">
    <re-input :value="dateValue" readonly @click="toggleCalendar"  cus-type="datePicker" :placeholder="$attrs.placeholder"/>

    <re-expand-container :visible.sync="visible" maxHeight="'auto'" bg="transparent">
      <v-calendar :attributes="calendarAttrs" @dayclick="onDayClick" />
    </re-expand-container>
  </div>
</template>
<script>
import { directive as onClickaway } from 'vue-clickaway';
import ReInput from '@/components/form/ReInput.vue';
import ReExpandContainer from '@/components/form/ReExpandContainer.vue';
import triggerValidate from '@/mixins/triggerValidate';

export default {
  name: 'ReDatePicker',
  components: {
    ReInput,
    ReExpandContainer
  },
  directives: {
    onClickaway
  },
  mixins: [triggerValidate],
  data() {
    return {
      calendarAttrs: [
        {
          key: 'today',
          highlight: true,
          dates: new Date()
        }
      ],
      dateValue: '',
      visible: false,
    };
  },
  methods: {
    toggleCalendar() {
      this.visible = !this.visible;
    },
    closeCalendar() {
      this.visible = false;
    },
    onDayClick(day) {
      this.dateValue = day.id;
      this.$emit('input', day.id);
      this.triggerValidate('change', day.id);
      this.visible = false;
    },
    initValue() {
      this.dateValue = '';
      this.$emit('input', '');
      this.triggerValidate('change', '');
      this.closeCalendar();
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/other/mixin.scss';
@import '@/assets/scss/other/color.scss';

.re-date-picker {
  position: relative;
}

.calendar-wrap {
  @include position(tl, 100%, 0);
}
</style>
