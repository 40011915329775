<template>
  <div class="re-select">
    <div class="re-select__input" v-on-clickaway="closeOptions">
      <re-input v-model="optLabel" @click.stop @input="handleChange" @keydown="handleKeydown" @focus="handleFocus"
        @blur="handleBlur" :placeholder="$attrs.placeholder" />
    </div>

    <re-expand-container :visible.sync="isExpand">
      <div class="re-select__option__content" ref="optsRefWrap">
        <ul class="re-select__option__content__list">

          <li class="re-select-option" :class="[
            {
              're-select-option--active': keyboardIndex === idx,
              're-select-option--disabled': opt.disabled
            }
          ]" @click="handleSelect(opt.label)" v-for="(opt, idx) of extraOptions" :key="opt.value" v-bind="opt">
            <component v-if="opt.render" :is="render()" v-bind="opt.optionConfig" />
            <span v-else class="re-select-option__item">{{ opt.label }}</span>
          </li>
        </ul>
      </div>
    </re-expand-container>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import ReEmailAutoCompleteOption from '@/components/form/ReEmailAutoCompleteOption.vue';
import ReInput from '@/components/form/ReInput.vue';
import ReExpandContainer from '@/components/form/ReExpandContainer.vue';
// import ReCategoryTitle from '@/components/form/ReCategoryTitle.vue';
import triggerValidate from '@/mixins/triggerValidate';
import { cloneDeep } from 'lodash';

export default {
  name: 'ReSelectWithInputFilter',
  mixins: [triggerValidate],
  components: {
    ReEmailAutoCompleteOption,
    ReInput,
    ReExpandContainer
  },
  directives: {
    onClickaway
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    storageKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isExpand: false,
      visible: false,
      optValue: '',
      optLabel: '',
      extraOptions: [],
      keyboardIndex: 0
    };
  },
  methods: {
    findMatchOption(val) {
      return this.options.find((item) => item.label === val);
    },
    setValue() {
      const matchObj = this.findMatchOption(this.value);

      if (matchObj) {
        this.optLabel = matchObj.label;
        this.optValue = matchObj.value;
      }
    },
    closeOptions() {
      this.isExpand = false;
    },
    cleanField() {
      this.optLabel = '';
      this.$emit('input', '');
      this.triggerValidate('change', '');
    },
    handleBlur(val) {
      const legalValueOpts = this.options.map((item) => item.value);

      if (val) {
        // 若有值，判斷是否在選項中
        const isLegal = this.handleSelect(val);

        if (!isLegal) {
          this.cleanField();
          return;
        }
      }

      if (!legalValueOpts.includes(this.optValue)) {
        this.cleanField();
      }
    },
    handleFocus() {
      this.setOptions();
    },
    handleSelect(countryLabel) {
      const matchObj = this.findMatchOption(countryLabel);

      if (matchObj) {
        this.optLabel = matchObj.label;
        this.optValue = matchObj.value;
        this.$emit('input', this.optValue);
        this.triggerValidate('change', this.optValue);
        // this.setOptions();
        this.closeOptions();
        return true;
      }

      return false;
    },

    handleScroll() {
      if (this.keyboardIndex > 4) {
        this.$refs.optsRefWrap.scrollTo({
          top: 40 * this.keyboardIndex
        });
      } else {
        this.$refs.optsRefWrap.scrollTo({
          top: 0
        });
      }
    },
    handleKeydown(e) {
      if (e.keyCode === 40) {
        this.keyboardIndex += 1;
        if (this.keyboardIndex >= this.extraOptions.length) {
          this.keyboardIndex = 0;
        }

        this.handleScroll();
      }

      if (e.keyCode === 38) {
        this.keyboardIndex -= 1;
        if (this.keyboardIndex === -1) {
          this.keyboardIndex = this.extraOptions.length - 1;
        }

        this.handleScroll();
      }

      if (e.keyCode === 13 && this.isExpand) {
        this.handleSelect(this.extraOptions[this.keyboardIndex].label);
      }
    },
    handleChange() {
      if (this.disabled) return;
      this.setOptions();
    },
    setOptions() {
      this.isExpand = true;
      const filteredOpts = this.options.filter((item) => item.label.includes(this.optLabel));

      if (filteredOpts.length > 0) {
        this.extraOptions = filteredOpts;
      } else {
        this.extraOptions = cloneDeep(this.options);
      }
    },
    initValue() {
      this.optValue = '';
      this.$emit('input', this.optValue);
      this.triggerValidate('change', this.optValue);
    }
  },
  created() {
    this.setValue();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.re-select {
  position: relative;
  width: 100%;

  &__input {
    width: 100%;
    cursor: pointer;

    &__field {
      @include padding(10px);
      width: 100%;
      background: $c-white;
      border: 2px solid $color-theme;
      border-radius: 10px;
    }
  }

  &__option {
    @include position(tl, 100%, 0);
    z-index: 100;
    margin-top: 5px;
    background-color: $c-white;
    border: 1px solid $color-theme;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;

    &__content {
      position: relative;
      height: auto;
      max-height: 200px;
      overflow: auto;
    }
  }
}

.re-select-option {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  @include font-style($c-black, 16);
  @include padding(10px);
  position: relative;
  height: 40px;

  &:not([data-disabled-status="true"]) {
    &:hover {
      background-color: rgba($color-theme, 0.2);
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--active {
    background-color: rgba($color-theme, 0.2);
  }

  &__remove {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    @include font-style($c-error, 14px);
    cursor: pointer;
  }
}
</style>
