var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"re-input",class:[
    `re-input--size--${_vm.size}`,
    {
      're-input--disabled': _vm.disabled,
    },
  ],on:{"click":_vm.handleClick}},[(_vm.$slots.prepend)?_c('div',{staticClass:"re-input__prepend"},[_vm._t("prepend")],2):_vm._e(),_c('div',{staticClass:"re-input__content",class:{
      're-input__content--prepend': _vm.$slots.prepend,
      're-input__content--append': _vm.$slots.append,
    }},[(_vm.$slots.prefix)?_c('div',{staticClass:"re-input__content__prefix"},[_vm._t("prefix")],2):_vm._e(),_c('input',_vm._b({ref:"input",staticClass:"re-input__content__native",class:{
        're-input__content__native--disabled': _vm.disabled,
        're-input__content__native--readonly': !_vm.disabled && _vm.cursorPointer,
        're-input__content__native--number': _vm.$attrs.type === 'number',
      },attrs:{"disabled":_vm.disabled,"type":_vm.type,"autocomplete":"off"},on:{"input":_vm.handleInput,"change":_vm.handleChange,"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keydown":_vm.handleKeydown}},'input',_vm.$attrs,false)),(_vm.$slots.suffix)?_c('div',{staticClass:"re-input__content__suffix"},[_vm._t("suffix")],2):_vm._e()]),(_vm.$slots.append)?_c('div',{staticClass:"re-input__append"},[_vm._t("append")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }