<template>
  <div class="re-select">
    <div class="re-select__input" v-on-clickaway="closeOptions">
      <re-input
        v-model="emailValue"
        @click.stop
        @input="handleChange"
        @change="handleChange"
        @keydown="handleKeydown"
        :placeholder="$attrs.placeholder"
      />
      <!-- @focus="expandOptions" -->
    </div>
    <re-expand-container :visible.sync="isExpand">
      <div class="re-select__option__content">
        <ul class="re-select__option__content__list">

          <li
            class="re-select-option"
            :class="[
              {
                're-select-option--active': keyboardIndex === idx,
                're-select-option--disabled': opt.disabled
              }
            ]"
            @click="handleSelect(opt.value)"
            v-for="(opt, idx) of extraOptions"
            :key="opt.value"
            v-bind="opt"
          >
            <component v-if="opt.render" :is="render()" v-bind="opt.optionConfig" />
            <span v-else class="re-select-option__item">{{ opt.label }}</span>
            <span
              v-show="opt.allowedDelete"
              class="re-select-option__remove"
              :allowedDelete="opt.allowedDelete"
              @click.stop="removeOption(opt.value)"
              >刪除</span
            >
          </li>
        </ul>
      </div>
    </re-expand-container>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import ReEmailAutoCompleteOption from '@/components/form/ReEmailAutoCompleteOption.vue';
import ReInput from '@/components/form/ReInput.vue';
import ReExpandContainer from '@/components/form/ReExpandContainer.vue';
import ReCategoryTitle from '@/components/form/ReCategoryTitle.vue';
import triggerValidate from '@/mixins/triggerValidate';

export default {
  name: 'ReEmailAutoComplete',
  mixins: [triggerValidate],
  components: {
    ReEmailAutoCompleteOption,
    ReInput,
    ReExpandContainer
  },
  directives: {
    onClickaway
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    storageKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isExpand: false,
      visible: false,
      emailValue: '',
      extraOptions: [],
      emailDomains: [],
      isBlur: false,
      isFocus: true,
      keyboardIndex: 0
    };
  },
  methods: {
    setValue() {
      this.emailValue = this.value;
    },
    closeOptions() {
      this.isExpand = false;
    },
    handleSelect(selectedValue) {
      let fullEmail = '';

      if (this.emailValue.indexOf('@') > -1) {
        const account = this.emailValue.split('@')[0];
        fullEmail = `${account}@${selectedValue}`;
      } else {
        fullEmail = `${this.emailValue}@${selectedValue}`;
      }

      this.emailValue = fullEmail;
      this.$emit('input', this.emailValue);
      this.triggerValidate('change', this.emailValue);
      this.setOptions();
      this.closeOptions();
    },
    removeOption(val) {
      const storageOptions = localStorage.getItem(this.storageKey);
      if (!storageOptions) return;

      const emailOptions = JSON.parse(storageOptions);

      // 要移除的 domain
      const filterOptions = emailOptions.filter((item) => item.value !== val);

      if (filterOptions.length === 0) {
        localStorage.removeItem(this.storageKey);
      } else {
        localStorage.setItem(this.storageKey, JSON.stringify(filterOptions));
      }

      this.combineEmailDomains();
    },
    expandOptions() {
      if (!this.emailValue || this.emailValue.split('@').length !== 2) {
        this.isExpand = false;
        return;
      }

      this.isExpand = true;
    },
    handleKeydown(e) {
      if (e.keyCode === 40) {
        this.keyboardIndex += 1;
        if (this.keyboardIndex >= this.extraOptions.length) {
          this.keyboardIndex = 0;
        }
      }

      if (e.keyCode === 38) {
        this.keyboardIndex -= 1;
        if (this.keyboardIndex === -1) {
          this.keyboardIndex = this.extraOptions.length - 1;
        }
      }

      if (e.keyCode === 13 && this.isExpand) {
        this.handleSelect(this.extraOptions[this.keyboardIndex].value);
      }
    },
    handleChange(value) {
      this.$emit('input', value);
      this.triggerValidate('input', value);

      if (this.disabled) return;

      // 沒有值或是沒有小老鼠分割沒有兩個值，不開啟
      if (!value || this.emailValue.split('@').length !== 2) {
        this.isExpand = false;
        return;
      }

      const mappingObj = this.extraOptions.find((item) => item.label.includes(value));

      // 若有 mapping 到 部分相同的字段
      if (mappingObj) {
        // 但 長度不同，則顯示
        const isMoreLength = value > mappingObj.label;

        if (mappingObj && isMoreLength) {
          this.isExpand = false;
          return;
        }
      } else {
        // 若 mapping 不到部分相同的字段
        this.isExpand = false;
        return;
      }

      this.isExpand = true;
    },
    combineEmailDomains() {
      this.emailDomains = this.options;

      const storageOptions = localStorage.getItem(this.storageKey);

      if (storageOptions) {
        let emailOptions = JSON.parse(storageOptions);
        emailOptions = emailOptions.map((item) => {
          item.allowedDelete = true;
          return item;
        });

        const frequentTitle = {
          render: () => ReCategoryTitle,
          label: 'frequentTitle',
          value: '111', // 只是一個隨機 id，渲染用
          title: '常用 Email',
          disabled: true
        };

        const historyTitle = {
          render: () => ReCategoryTitle,
          label: 'historyTitle',
          value: '222', // 只是一個隨機 id，渲染用
          title: '曾使用 Email',
          disabled: true
        };

        this.emailDomains = [frequentTitle, ...this.options, historyTitle, ...emailOptions];
      }

      this.setOptions();
    },
    setOptions() {
      const [account, domain] = this.emailValue.split('@');

      this.extraOptions = this.emailDomains.map((item) => {
        item.label = `${account}@${item.value}`;
        return item;
      });

      if (domain) {
        const isMatchDomain = this.emailDomains.some((item) => item.value.includes(domain));

        if (isMatchDomain) {
          this.extraOptions = this.extraOptions.filter((item) => item.value.includes(domain));
        }
      }
    },
    initValue() {
      this.emailValue = '';
      this.$emit('input', this.emailValue);
      this.triggerValidate('change', this.emailValue);
    }
  },
  created() {
    this.setValue();
    this.combineEmailDomains();
  },
  watch: {
    emailValue: {
      handler(newVal, oldVal) {
        if (newVal.length !== oldVal.length) {
          this.setOptions();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";
.re-select {
  position: relative;
  width: 100%;

  &__input {
    width: 100%;
    cursor: pointer;

    &__field {
      @include padding(10px);
      width: 100%;
      background: $c-white;
      border: 2px solid $color-theme;
      border-radius: 10px;
    }
  }

  &__option {
    @include position(tl, 100%, 0);
    z-index: 100;
    margin-top: 5px;
    background-color: $c-white;
    border: 1px solid $color-theme;
    border-radius: 4px;
    overflow: hidden;

    &__content {
      position: relative;
      height: auto;
      max-height: 200px;
      overflow: auto;
    }
  }
}

.re-select-option {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  @include font-style($c-black, 14px);
  @include padding(10px);
  position: relative;

  &:not([data-disabled-status="true"]) {
    &:hover {
      background-color: rgba($color-theme, 0.2);
    }
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--active {
    background-color: rgba($color-theme, 0.2);
  }

  &__remove {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    @include font-style($c-error, 14px);
    cursor: pointer;
  }
}
</style>
